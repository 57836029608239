import React, { useMemo, useState } from 'react';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { isFuture } from 'date-fns';
import {
  ECOMMERCE_ROOT,
  useColorVariant,
  useNumberFormatter,
  isProductPurchaseable,
  GlobalSelectors,
  mapProductOrVariantToAnalyticsItem,
} from '@swibeco/shared';
import { Popover, Text } from '@swibeco/ui';
import { Flex, Card, CardBody } from '@chakra-ui/react';
import {
  AnalyticsEvents,
  AnalyticsContext,
  Product,
  EnumProductType,
} from '@swibeco/types';
import { useProductVariants } from '@swibeco/ecommerce';
import {
  actions,
  usePlatform,
  selectors as coreSelectors,
} from '@swibeco/core';
import { useCurrentCompany } from '@swibeco/security';
import { ReactComponent as CashbackIcon } from '../../assets/images/cashback_icon.svg';
import { VoucherModal } from '../Voucher/VoucherModal';
import swipointsIcon from '../../assets/images/swipoints.svg';
import flashDealIcon from '../../assets/images/flash-deal.svg';
import permanentDealIcon from '../../assets/images/permanent-deal.svg';
import inhouseDealIcon from '../../assets/images/in-house-deal.svg';
import * as Styles from './ProductItem.styles';
import { ProductItemButton } from './ProductItemButton';
import { useTimeLeftLabel } from '../../utils/useTimeLeftLabel';
import ProductImage from './ProductImage';
import santaGif from '../../assets/images/santa.gif';

export type ProductItemProps = {
  product: Product;
  onAddItem: (
    product: { id: number },
    quantity: number
  ) => Promise<
    | false
    | {
        quantity: number;
        deal: any;
        response: any;
      }
  >;
  analytics?: AnalyticsContext;
  category?: string;
  subcategory?: string;
};

const ProductItem = ({
  product,
  onAddItem,
  analytics,
  category,
  subcategory,
}: ProductItemProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const swipointsBalance = useSelector(
    GlobalSelectors.user.getSwipointsBalance
  );
  const locale = useSelector(coreSelectors.getLocale);
  const { company } = useCurrentCompany();
  const companyName = company?.name;
  const mainColorVariant = useColorVariant('primary', 'main');
  const formatter = useNumberFormatter();
  const purchasableProduct = isProductPurchaseable(product);
  const endDatetime = product?.endDate;

  const hasLimitedTime = endDatetime && isFuture(new Date(endDatetime));
  const isProductNew =
    product?.recentUntilDate && isFuture(new Date(product.recentUntilDate));
  const showFlashIcon = hasLimitedTime;
  const showInhouseIcon = product?.isPrivate;
  const showSwipointsIcon = purchasableProduct && swipointsBalance > 0;
  const showPermanentIcon = !hasLimitedTime;
  const hasDiscount = product.variantsMaximumDiscount > 0;
  const hasCashback = product.defaultVariantData.cashbackAmount > 0;
  const [animationKey, setAnimationKey] = useState(0);
  const [animate, setAnimate] = useState(false);
  const christmasFlag = false;
  const isFreeVoucher =
    product.type === EnumProductType.Voucher &&
    product.variantsMinimumPrice === 0;
  const [open, setOpen] = useState(false);
  const environment = usePlatform(window);
  const { products: productVariants, isLoading } = useProductVariants({
    slug: product?.defaultVariantData.slug,
    options: {
      enabled: product && product?.type === EnumProductType.GenericCode && open,
    },
  });

  const outOfStock = false;
  const selectItem = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const targetElement = e.target as HTMLElement;
    const tagNameValue = targetElement.tagName.toLowerCase();
    if (
      analytics &&
      product &&
      tagNameValue !== 'button' &&
      tagNameValue !== 'path' &&
      tagNameValue !== 'svg'
    ) {
      const toAnalytics = mapProductOrVariantToAnalyticsItem(analytics, locale);
      const items = [toAnalytics(product)];
      TagManager.dataLayer({
        dataLayer: {
          event: AnalyticsEvents.SELECT_ITEM,
          environment,
          ecommerce: {
            items,
          },
        },
      });
    }
  };
  // nedeed for analytics
  const handleAddItem = async (product: { id: number }) => {
    const res = await onAddItem(product, 1);
    if (res) {
      if (christmasFlag) {
        setAnimationKey((prev) => prev + 1);
        setAnimate(true);
      }
      dispatch(actions.showOnAddItem(1));
      setTimeout(() => {
        dispatch(actions.hideOnAddItem());
      }, 3000);
    }
  };

  const timeLeftLabel = useTimeLeftLabel(endDatetime);
  const productUrlSearchParams = useMemo(() => {
    const searchParams = new URLSearchParams();
    if (category) {
      searchParams.append('c', category);
    }
    if (subcategory) {
      searchParams.append('s', subcategory);
    }
    if (!category && product.isPrivate) {
      searchParams.append('c', 'mycompanybenefits');
    }
    return searchParams;
  }, [product, category, subcategory]);

  const productRoute = `${ECOMMERCE_ROOT}/product/${
    product?.defaultVariantData.slug || product?.slug
  }?${productUrlSearchParams.toString()}`;
  const voucherData = {
    code: productVariants?.[0]?.genericCode ?? '',
    externalLink: productVariants?.[0]?.translationsMap?.externalLink,
    deliveryDescription: product.defaultVariantData?.deliveryDescription,
  };

  const borderColor = useMemo(() => {
    if (product.type === EnumProductType.DropShipping) {
      if (isProductNew) {
        return 'default.black';
      }
      if (hasLimitedTime) {
        return 'complementary.red';
      }
    }
    return 'shadow.primary';
  }, [product, hasLimitedTime, isProductNew]);
  const borderTop = useMemo(() => {
    if (product.type === EnumProductType.DropShipping) {
      if (isProductNew) {
        return '3px solid';
      }
      if (hasLimitedTime) {
        return '3px solid';
      }
    }
    return '0px';
  }, [product, hasLimitedTime, isProductNew]);

  return (
    <Card
      data-testid={`product-card-${product.id}`}
      id="product-card"
      className="h-100"
      position="relative"
      pointerEvents={outOfStock ? 'none' : 'auto'}
      borderTop={borderTop}
      borderColor={borderColor}
      width={{ base: '100%', xl: '280px', xxl: '100%' }}
      maxHeight={{ base: '313px', sm: '313px' }}
      maxWidth={{ base: '100%', sm: '300px' }}
      _hover={{
        '.product-card-image': {
          transform: 'scale(1.02, 1.02)',
          filter: 'none',
        },
        '.product-voucher-ticket': { bottom: '30px' },
      }}
    >
      <Styles.ProductLink
        className="text-decoration-none h-100"
        to={productRoute}
        id={`product-icons-container-${product.id}`}
        onClick={selectItem}
      >
        {outOfStock && <Styles.OpacityFilter />}
        <VoucherModal
          isOpen={open}
          toggle={() => setOpen((o) => !o)}
          voucherTitle={productVariants?.[0]?.translationsMap.freeTextDiscount}
          voucherBrandImage={productVariants?.[0]?.product?.brand?.logoName}
          dealImage={productVariants?.[0]?.product?.images?.[0]?.path}
          voucherInfo={voucherData}
          isLoading={isLoading}
        />
        <ProductImage
          product={product}
          imageHasMargin
          isFlash={hasLimitedTime || false}
          isProductNew={isProductNew}
        />
        {/* order of conditions matters, isProductNew should be check first */}
        <Flex flexDir="column" gap="8px" pos="absolute" top="20px" right="0">
          {isProductNew && (
            <Styles.ProductTypeLabel data-testid="product-type-label-1">
              {t('core.deal.label.new')}
            </Styles.ProductTypeLabel>
          )}
          {hasLimitedTime && (
            <Styles.ProductTypeLabel data-testid="product-type-label-2" isFlash>
              {timeLeftLabel}
            </Styles.ProductTypeLabel>
          )}
          {product.isPrivate && (
            <Styles.ProductTypeLabel
              data-testid="product-type-label"
              isInternal
            >
              {t('core.deal.label.inhouse')}
            </Styles.ProductTypeLabel>
          )}
        </Flex>
        <CardBody
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          data-testid="card-body"
          padding="0 1.2rem 8px 1.2rem"
        >
          <Styles.ProductTitle className="align-items-center">
            <Flex
              alignItems="flex-start"
              direction="column"
              justifyContent="flex-start"
              minH="58px"
            >
              <Styles.ShortTitle
                data-testid="product-title"
                className="mb-2"
                noOfLines={1}
                textOverflow="ellipsis"
              >
                {(() => {
                  switch (product.type) {
                    case EnumProductType.LandingPage:
                    case EnumProductType.GenericCode:
                      return product.defaultVariantData.shortName;
                    default:
                      return product.name;
                  }
                })()}
              </Styles.ShortTitle>
              {/* TODO: If the product has discountFreeText, we only show that one and ignore price */}

              {purchasableProduct ? (
                <div className="d-flex flex-wrap text-wrap align-items-center">
                  {(() => {
                    if (isFreeVoucher) {
                      return (
                        <Styles.ProductPrice
                          className="pr-1"
                          data-testid="product-price"
                          color={mainColorVariant}
                        >
                          {t('core.ecommerce.basketpage.free')}
                        </Styles.ProductPrice>
                      );
                    }
                    if (hasDiscount) {
                      if (product.type === EnumProductType.Voucher) {
                        return (
                          <Text
                            color="primary.main"
                            bold
                            data-testid="product-discount"
                          >
                            {`${product.variantsMaximumDiscount.toFixed(0)}%`}{' '}
                            {t('core.product_item.discount')}
                          </Text>
                        );
                      }
                      return (
                        <Flex
                          gap="8px"
                          data-testid="product-price-and-discount"
                        >
                          <Text color="primary.main" bold>
                            {product.isConfigurable &&
                              t('core.product_item.from')}{' '}
                            {formatter(product.variantsMinimumPrice / 100)}
                          </Text>
                          <Text
                            border="1px solid"
                            borderColor="primary.main"
                            borderRadius="15px"
                            padding="0 8px"
                            data-testid="product-discount"
                            color="primary.main"
                          >
                            {product.isConfigurable &&
                              t('core.product_item.up_to')}{' '}
                            {t('core.hyphen')}
                            {`${product?.variantsMaximumDiscount.toFixed(0)}%`}
                          </Text>
                        </Flex>
                      );
                    }
                    if (hasCashback) {
                      if (product.type === EnumProductType.Voucher) {
                        return (
                          <Flex gap="8px" alignItems="center">
                            <CashbackIcon width="20px" height="20px" />
                            <Text
                              color="primary.main"
                              bold
                              data-testid="product-cashback-percentage"
                            >
                              {`${(
                                (product.defaultVariantData.cashbackAmount /
                                  product.defaultVariantData.price) *
                                100
                              ).toFixed(0)}%`}{' '}
                              {t('core.ecommerce.cashback')}
                            </Text>
                          </Flex>
                        );
                      }
                      // not treated yet, does not exist in the backoffice @Hala said so
                      return <div />;
                    }
                    return (
                      <Text
                        data-testid="product-discount"
                        color="primary.main"
                        // w="min-content"
                        bold
                      >
                        {product.isConfigurable && t('From')}{' '}
                        {`${formatter(product.variantsMinimumPrice / 100)}`}
                      </Text>
                    );
                  })()}
                </div>
              ) : (
                <div
                  className="d-flex flex-wrap text-wrap align-items-center"
                  data-testid="deal-final-price"
                >
                  <Styles.ProductPrice
                    className="pr-1"
                    data-testid="product-price"
                    color={mainColorVariant}
                  >
                    {product?.defaultVariantData?.freeTextDiscount}
                  </Styles.ProductPrice>
                </div>
              )}
            </Flex>
          </Styles.ProductTitle>
          <Styles.Separator />
          <Flex alignItems="center" justifyContent="space-between">
            <Styles.IconContainer>
              {/* TODO: align middle of popover span */}
              {showSwipointsIcon && (
                <Popover
                  placement="top-start"
                  popoverTrigger={
                    <Styles.IconItem
                      id={`product-swipoints-icon-tooltip-${product.id}`}
                    >
                      <Styles.ProductTypeIcon
                        onClick={(e) => e.preventDefault()}
                        src={swipointsIcon}
                        alt="Swipoints"
                      />
                    </Styles.IconItem>
                  }
                  popoverTriggerProps={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Text fontSize="sm">
                    {t('core.deal.iconstooltip.swipoints', {
                      company: companyName,
                    })}
                  </Text>
                </Popover>
              )}
              {showPermanentIcon && (
                <Popover
                  placement="top-start"
                  popoverTrigger={
                    <Styles.IconItem
                      id={`product-permanent-icon-tooltip-${product.id}`}
                    >
                      <Styles.ProductTypeIcon
                        onClick={(e) => e.preventDefault()}
                        src={permanentDealIcon}
                        alt="Permanent Deal"
                      />
                    </Styles.IconItem>
                  }
                  popoverTriggerProps={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Text fontSize="sm">
                    {t('core.deal.iconstooltip.permanent', {
                      company: companyName,
                    })}
                  </Text>
                </Popover>
              )}
              {showFlashIcon && (
                <Popover
                  placement="top-start"
                  popoverTrigger={
                    <Styles.IconItem
                      id={`product-flash-icon-tooltip-${product.id}`}
                    >
                      <Styles.ProductTypeIcon
                        onClick={(e) => e.preventDefault()}
                        src={flashDealIcon}
                        alt="Flash Deal"
                      />
                    </Styles.IconItem>
                  }
                  popoverTriggerProps={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Text fontSize="sm">
                    {t('core.deal.iconstooltip.flash', {
                      company: companyName,
                    })}
                  </Text>
                </Popover>
              )}
              {showInhouseIcon && (
                <Popover
                  placement="top-start"
                  popoverTrigger={
                    <Styles.IconItem
                      id={`product-inhouse-icon-tooltip-${product.id}`}
                    >
                      <Styles.ProductTypeIcon
                        onClick={(e) => e.preventDefault()}
                        src={inhouseDealIcon}
                        alt="Inhouse Deal"
                      />
                    </Styles.IconItem>
                  }
                  popoverTriggerProps={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Text fontSize="sm">
                    {t('core.deal.iconstooltip.inhouse', {
                      company: companyName,
                    })}
                  </Text>
                </Popover>
              )}
            </Styles.IconContainer>
            <Styles.ButtonContainer onClick={(e) => e.preventDefault()}>
              <motion.div
                style={{
                  position: 'absolute',
                  visibility: 'hidden',
                }}
                initial={{
                  x: 0,
                  y: 0,
                  visibility: 'hidden',
                }}
                animate={
                  animate
                    ? {
                        x: 75,
                        y: -75,
                        visibility: 'visible',
                      }
                    : {
                        x: 0,
                        y: 0,
                        visibility: 'hidden',
                      }
                }
                transition={{ duration: 1.5 }}
                onAnimationComplete={() => setAnimate(false)}
                key={animationKey}
              >
                <img src={santaGif} alt="santa" />
              </motion.div>
              <ProductItemButton
                data-testid="product-item-button"
                disabled={outOfStock}
                isConfigurable={product.isConfigurable}
                // url="product.url"
                productRoute={productRoute}
                type={product?.type}
                // promoLink={product?.promoLink}
                isPrivate={product?.isPrivate}
                onOpenVoucherModal={() => setOpen(true)}
                onAddItem={handleAddItem}
                slug={product.defaultVariantData.slug}
                id={product.defaultVariantData.id}
              />
            </Styles.ButtonContainer>
          </Flex>
        </CardBody>
      </Styles.ProductLink>
    </Card>
  );
};

export default ProductItem;
