import React, { lazy, Suspense } from 'react';
import { darkenLighten, Loader } from '@swibeco/ui';
import { styled } from '@swibeco/shared';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { ErrorBoundary, ErrorPage } from '@swibeco/core-web';

const Gradient = styled.div`
  background: rgb(59, 142, 255);
  background: ${({ theme }) =>
    `linear-gradient(180deg, ${theme.colors.primary.main} 0%, ${darkenLighten(
      theme.colors.primary.main,
      -25
    )} 100%);`};
  height: calc(100vh - 70px);
  min-height: 600px;
  width: 100%;
  padding: 15px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
`;

/* istanbul ignore next */
const SwitchSSOUserPage = lazy(
  () => import('../pages/SwitchSSOUserPage/SwitchSSOUserPage')
);
const RedirectToLoginPage = lazy(
  () => import('../pages/RedirectToLoginPage/RedirectToLoginPage')
);

const SecurityRouter = () => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();

  return (
    <ErrorBoundary>
      <Gradient>
        <Helmet>
          <title>{t('security.login.page.title')}</title>
        </Helmet>
        <Container>
          <Wrapper>
            <Suspense fallback={<Loader />}>
              <Switch>
                <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
                <Route
                  path={`${path}choose-sso-company`}
                  component={SwitchSSOUserPage}
                />
                <Route path={path} component={RedirectToLoginPage} />
                <Route path="*" component={ErrorPage} />
              </Switch>
            </Suspense>
          </Wrapper>
        </Container>
      </Gradient>
    </ErrorBoundary>
  );
};

export default SecurityRouter;
