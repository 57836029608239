import React, { useMemo, useState } from 'react';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { isFuture } from 'date-fns';
import {
  ECOMMERCE_ROOT,
  mapDealToAnalytics,
  useColorVariant,
  useNumberFormatter,
  isBuyableWithSwipoints,
  hasCashback,
  isVoucher,
  GlobalSelectors,
} from '@swibeco/shared';
import { Popover, Text } from '@swibeco/ui';
import {
  AnalyticsEvents,
  AnalyticsContext,
  BaseDeal,
  EnumDealOfferType,
  ProductPageDeal,
} from '@swibeco/types';
import { ecommerceApi } from '@swibeco/ecommerce';
import { selectors as coreSelectors, usePlatform } from '@swibeco/core';
import { useCurrentCompany } from '@swibeco/security';
import CashbackChip from '../CashbackChip/CashbackChip';
import { VoucherModal } from '../Voucher/VoucherModal';
import swipointsIcon from '../../assets/images/swipoints.svg';
import flashDealIcon from '../../assets/images/flash-deal.svg';
import permanentDealIcon from '../../assets/images/permanent-deal.svg';
import inhouseDealIcon from '../../assets/images/in-house-deal.svg';
import * as Styles from './DealItem.styles';
import { DealItemButton } from './DealItemButton';
import { useTimeLeftLabel } from '../../utils/useTimeLeftLabel';
import DealImage from './DealImage';
import santaGif from '../../assets/images/santa.gif';

export type DealItemProps = {
  deal: BaseDeal | ProductPageDeal;
  onAddItem: (deal: BaseDeal | ProductPageDeal, quantity: number) => void;
  analytics?: AnalyticsContext;
  category?: string;
  subcategory?: string;
};

export function isProductPageDeal(
  deal: BaseDeal | ProductPageDeal
): deal is ProductPageDeal {
  return (deal as ProductPageDeal).title !== undefined;
}

const DealItem = ({
  deal,
  onAddItem,
  analytics,
  category,
  subcategory,
}: DealItemProps) => {
  const { t } = useTranslation();
  const swipointsBalance = useSelector(
    GlobalSelectors.user.getSwipointsBalance
  );
  const { company } = useCurrentCompany();
  const companyName = company?.name;
  const locale = useSelector(coreSelectors.getLocale);
  const mainColorVariant = useColorVariant('primary', 'main');
  const darkColorVariant = useColorVariant('default', 'dark');
  const formatter = useNumberFormatter();
  const buyableWithSwipoints = isBuyableWithSwipoints(deal);
  const { endDatetime } = deal;

  const hasLimitedTime =
    deal.isFlash && endDatetime && isFuture(new Date(endDatetime));
  const isDealNew =
    deal.recentUntilDate && isFuture(new Date(deal.recentUntilDate));
  const showFlashIcon = hasLimitedTime;
  const showPermanentIcon = deal.isPermanent;
  const showInhouseIcon = deal.isInternal;
  const showSwipointsIcon = buyableWithSwipoints && swipointsBalance > 0;
  const hasDiscount = deal.listPrice - deal.finalPrice > 0;
  const displayImage = deal.thumbnailUrl;
  const [animationKey, setAnimationKey] = useState(0);
  const [animate, setAnimate] = useState(false);
  const christmasFlag = false;

  const isFreeVoucher = deal.offerType === 'voucher' && deal.finalPrice === 0;
  const dealTitle =
    isProductPageDeal(deal) && showInhouseIcon ? deal.title : deal.shortTitle;

  const [open, setOpen] = useState(false);
  const environment = usePlatform(window);

  const { data } = useQuery({
    queryKey: ['voucher-info', deal.id, locale],
    queryFn: () => ecommerceApi.getDealSharedCodeInfo(locale, deal.id),
    enabled: open && deal.offerType === EnumDealOfferType.PromoCode,
  });

  const outOfStock =
    // eslint-disable-next-line no-nested-ternary
    'stock' in deal ? (deal.stock !== null ? deal.stock === 0 : false) : false;
  const selectItem = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const targetElement = e.target as HTMLElement;
    const tagNameValue = targetElement.tagName.toLowerCase();
    if (
      analytics &&
      deal &&
      tagNameValue !== 'button' &&
      tagNameValue !== 'path' &&
      tagNameValue !== 'svg'
    ) {
      const toAnalytics = mapDealToAnalytics(analytics);
      const items = [toAnalytics(deal, 0)];
      TagManager.dataLayer({
        dataLayer: {
          event: AnalyticsEvents.SELECT_ITEM,
          environment,
          ecommerce: {
            items,
          },
        },
      });
    }
  };
  // nedeed for analytics
  const handleAddItem = async () => {
    onAddItem(deal, 1);
    if (christmasFlag) {
      setAnimationKey((prev) => prev + 1);
      setAnimate(true);
    }
  };

  const timeLeftLabel = useTimeLeftLabel(endDatetime);
  const dealUrlSearchParams = useMemo(() => {
    const searchParams = new URLSearchParams();
    if (category) {
      searchParams.append('c', category);
    }
    if (subcategory) {
      searchParams.append('s', subcategory);
    }
    if (!category && deal.isInternal) {
      searchParams.append('c', 'mycompanybenefits');
    }
    return searchParams;
  }, [deal, category, subcategory]);

  const dealRoute = `${ECOMMERCE_ROOT}/product/${
    deal?.slug
  }?${dealUrlSearchParams.toString()}`;

  return (
    <Styles.DealCard
      data-testid="card"
      id="deal-card"
      className="h-100"
      outOfStock={outOfStock}
    >
      <Styles.DealLink
        className="text-decoration-none h-100"
        to={dealRoute}
        id={`deal-icons-container-${deal.id}`}
        onClick={selectItem}
      >
        {outOfStock && <Styles.OpacityFilter />}
        <VoucherModal
          isOpen={open}
          toggle={() => setOpen((o) => !o)}
          voucherTitle={deal.discount}
          voucherBrandImage={deal.branding?.logo}
          dealImage={displayImage}
          voucherInfo={data}
        />
        <DealImage deal={deal} imageHasMargin />
        {/* order of conditions matters, isDealNew should be check first */}
        {/* eslint-disable-next-line no-nested-ternary */}
        {(() => {
          if (deal.isInternal) {
            return (
              <Styles.DealTypeLabel data-testid="deal-type-label" isInternal>
                {t('core.deal.label.inhouse')}
              </Styles.DealTypeLabel>
            );
          }
          if (isDealNew) {
            return (
              <Styles.DealTypeLabel data-testid="deal-type-label">
                {t('core.deal.label.new')}
              </Styles.DealTypeLabel>
            );
          }
          if (hasLimitedTime) {
            return (
              <Styles.DealTypeLabel data-testid="deal-type-label" isFlash>
                {timeLeftLabel}
              </Styles.DealTypeLabel>
            );
          }
          return null;
        })()}
        <Styles.DealCardBody>
          <Styles.DealTitle className="align-items-center">
            <Styles.ShortTitle
              data-testid="deal-title"
              className="mb-2 text-truncate"
            >
              {dealTitle}
            </Styles.ShortTitle>

            {buyableWithSwipoints ? (
              <div className="d-flex flex-wrap text-wrap align-items-center">
                {!hasCashback(deal) && (
                  <Styles.DealPrice
                    className="pr-1"
                    data-testid="deal-price"
                    color={mainColorVariant}
                  >
                    {isFreeVoucher
                      ? t('core.ecommerce.basketpage.free')
                      : formatter(deal.finalPrice)}
                  </Styles.DealPrice>
                )}
                {hasDiscount && (
                  <>
                    <Styles.ListPrice
                      data-testid="deal-list-price"
                      className="pr-1"
                      color={darkColorVariant}
                    >
                      <del>{formatter(deal.listPrice)}</del>
                    </Styles.ListPrice>
                    <Styles.DealDiscountPill>
                      <Styles.DiscountPercent
                        data-testid="deal-discount"
                        color={mainColorVariant}
                      >
                        <>
                          {t('core.hyphen')}
                          {deal.discount}
                        </>
                      </Styles.DiscountPercent>
                    </Styles.DealDiscountPill>
                  </>
                )}
                {hasCashback(deal) && isVoucher(deal) && (
                  <>
                    <Styles.DealPrice
                      className="pr-1"
                      data-testid="deal-price"
                      color={mainColorVariant}
                    >
                      {formatter(deal.cashbackAmount)}{' '}
                      {t('core.ecommerce.cashback')}
                    </Styles.DealPrice>
                    <CashbackChip
                      value={deal.cashbackPercentage}
                      data-testid="deal-cashback-chip"
                    />
                  </>
                )}
                {hasCashback(deal) && !isVoucher(deal) && (
                  <>
                    <Styles.DealPrice
                      className="pr-1"
                      data-testid="deal-price"
                      color={mainColorVariant}
                    >
                      {formatter(deal.finalPrice)}
                    </Styles.DealPrice>
                    <CashbackChip
                      value={deal.cashbackPercentage}
                      data-testid="deal-cashback-chip"
                    >
                      {t('core.ecommerce.cashback')}
                    </CashbackChip>
                  </>
                )}
              </div>
            ) : (
              <Styles.FreeTextDiscount
                data-testid="deal-discount"
                color={mainColorVariant}
              >
                {deal.discount}
              </Styles.FreeTextDiscount>
            )}
          </Styles.DealTitle>

          <Styles.Separator />
          <div className="d-flex justify-content-between align-items-end">
            <Styles.IconContainer>
              {showSwipointsIcon && (
                <Popover
                  placement="top-start"
                  popoverTrigger={
                    <Styles.IconItem
                      id={`deal-swipoints-icon-tooltip-${deal.id}`}
                    >
                      <Styles.DealTypeIcon
                        onClick={(e) => e.preventDefault()}
                        src={swipointsIcon}
                        alt="Swipoints"
                      />
                    </Styles.IconItem>
                  }
                >
                  <Text fontSize="sm">
                    {t('core.deal.iconstooltip.swipoints', {
                      company: companyName,
                    })}
                  </Text>
                </Popover>
              )}
              {showPermanentIcon && (
                <Popover
                  placement="top-start"
                  popoverTrigger={
                    <Styles.IconItem
                      id={`deal-permanent-icon-tooltip-${deal.id}`}
                    >
                      <Styles.DealTypeIcon
                        onClick={(e) => e.preventDefault()}
                        src={permanentDealIcon}
                        alt="Permanent Deal"
                      />
                    </Styles.IconItem>
                  }
                >
                  <Text fontSize="sm">
                    {t('core.deal.iconstooltip.permanent', {
                      company: companyName,
                    })}
                  </Text>
                </Popover>
              )}
              {showFlashIcon && (
                <Popover
                  placement="top-start"
                  popoverTrigger={
                    <Styles.IconItem id={`deal-flash-icon-tooltip-${deal.id}`}>
                      <Styles.DealTypeIcon
                        onClick={(e) => e.preventDefault()}
                        src={flashDealIcon}
                        alt="Flash Deal"
                      />
                    </Styles.IconItem>
                  }
                >
                  <Text fontSize="sm">
                    {t('core.deal.iconstooltip.flash', {
                      company: companyName,
                    })}
                  </Text>
                </Popover>
              )}
              {showInhouseIcon && (
                <Popover
                  placement="top-start"
                  popoverTrigger={
                    <Styles.IconItem
                      id={`deal-inhouse-icon-tooltip-${deal.id}`}
                    >
                      <Styles.DealTypeIcon
                        onClick={(e) => e.preventDefault()}
                        src={inhouseDealIcon}
                        alt="Inhouse Deal"
                      />
                    </Styles.IconItem>
                  }
                >
                  <Text fontSize="sm">
                    {t('core.deal.iconstooltip.inhouse', {
                      company: companyName,
                    })}
                  </Text>
                </Popover>
              )}
            </Styles.IconContainer>
            <Styles.ButtonContainer onClick={(e) => e.preventDefault()}>
              <motion.div
                style={{
                  position: 'absolute',
                  visibility: 'hidden',
                }}
                initial={{
                  x: 0,
                  y: 0,
                  visibility: 'hidden',
                }}
                animate={
                  animate
                    ? {
                        x: 75,
                        y: -75,
                        visibility: 'visible',
                      }
                    : {
                        x: 0,
                        y: 0,
                        visibility: 'hidden',
                      }
                }
                transition={{ duration: 1.5 }}
                onAnimationComplete={() => setAnimate(false)}
                key={animationKey}
              >
                <img src={santaGif} alt="santa" />
              </motion.div>
              <DealItemButton
                data-testid="deal-item-button"
                disabled={outOfStock}
                url={deal.url}
                dealRoute={dealRoute}
                offerType={deal?.offerType}
                promoLink={deal?.promoLink}
                isInternal={deal?.isInternal}
                onOpenVoucherModal={() => setOpen(true)}
                onAddItem={handleAddItem}
              />
            </Styles.ButtonContainer>
          </div>
        </Styles.DealCardBody>
      </Styles.DealLink>
    </Styles.DealCard>
  );
};

export default DealItem;
