import { useLanguageSwitcher, actions as CoreActions } from '@swibeco/core';
import {
  useRefreshToken,
  useCurrentUser,
  useCurrentCompany,
} from '@swibeco/security';
import { breakpoints, css, Global, GlobalActions } from '@swibeco/shared';
import { Layout } from '@swibeco/shared-web';
import { customTheme, Loader, SwibecoColors, UIProvider } from '@swibeco/ui';
import Colr from 'colr';
import { AnimatePresence, motion } from 'framer-motion';
import React, { Suspense, useMemo, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import Router from 'router/Router';
import { BrowserRouter } from 'react-router-dom';
import * as Styles from './App.styles';

const App = () => {
  const dispatch = useDispatch();
  const refreshTokenQuery = useRefreshToken();
  const { setLocale } = useLanguageSwitcher();
  const { user, isSuccess: isUserSuccess } = useCurrentUser();
  const { company, isLoading, isSuccess } = useCurrentCompany();

  //only trigger once, after the first user success call
  useEffect(() => {
    if (isUserSuccess && user) {
      dispatch(CoreActions.setLocale(user.language.toString()));
      setLocale(user.language.toString());
      dispatch(GlobalActions.user.setUser(user));
    }
  }, [isUserSuccess, setLocale, dispatch, user]);

  const theme = useMemo((): any => {
    if (isSuccess && company) {
      const primary = Colr.fromHex(company.theme.main_color);

      return {
        colors: {
          ...SwibecoColors.colors,
          primary: {
            ...SwibecoColors.colors.primary,
            // override default swibeco colors
            main: company.theme.main_color,
            light: primary.clone().lighten(19.3).toHex(),
            strong: primary.clone().darken(15).toHex(),
            dark: primary.clone().darken(34.8).toHex(),
          },
          shadow: {
            ...SwibecoColors.colors.shadow,
            // override default swibeco colors
            secondary: `${company.theme.main_color}3D`,
          },
        },
        initial: {
          colors: {
            primary: SwibecoColors.colors.primary,
            shadow: SwibecoColors.colors.shadow,
          },
        },
      };
    }
  }, [isSuccess, company]);

  const customChakraTheme = useMemo((): any => {
    if (isSuccess && company) {
      const primary = Colr.fromHex(company.theme.main_color);

      return {
        ...customTheme,
        colors: {
          ...customTheme.colors,
          ...SwibecoColors.colors,
          primary: {
            ...customTheme.colors.primary,
            ...SwibecoColors.colors.primary,
            // override default swibeco colors
            main: company.theme.main_color!,
            light: primary.clone().lighten(19.3).toHex(),
            strong: primary.clone().darken(15).toHex(),
            dark: primary.clone().darken(34.8).toHex(),
          },
          shadow: {
            ...customTheme.colors.shadow,
            ...SwibecoColors.colors.shadow,
            // override default swibeco colors
            secondary: `${company.theme.main_color}3D`,
          },
        },
      };
    }
  }, [isSuccess, company]);

  return (
    <AnimatePresence>
      {!refreshTokenQuery.isLoading && !isLoading ? (
        // https://github.com/roman-rr/cupertino-pane/issues/169 className is required for the pane to work
        <motion.div
          key="page"
          data-testid="page"
          style={{ minHeight: '100vh' }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ default: { duration: 0.5 } }}
          className="app"
        >
          <UIProvider theme={theme} customTheme={customChakraTheme}>
            <Global
              styles={css`
                html {
                  font-size: 17px;
                }
                .backdrop {
                  z-index: 2001 !important;
                }
                .pane {
                  z-index: 2002 !important;
                }
                ${breakpoints.md_max} {
                  .chakra-toast {
                    position: relative;
                    top: 66px;
                  }
                }
                ${breakpoints.md} {
                  .chakra-toast {
                    position: relative;
                    top: 158px;
                  }
                }
              `}
            />

            <Helmet>
              {company?.theme.favicon && (
                <link
                  rel="icon"
                  href={company?.theme.favicon.path!}
                  type={company?.theme.favicon.mime_type!}
                />
              )}
            </Helmet>
            <Styles.Container>
              <Suspense fallback={<Loader />}>
                <BrowserRouter basename="/v2">
                  <Layout>
                    <Router />
                  </Layout>
                </BrowserRouter>
              </Suspense>
            </Styles.Container>
          </UIProvider>
        </motion.div>
      ) : (
        <motion.div
          key="loading"
          style={{ height: '100vh' }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ default: { duration: 0.5 } }}
        >
          <Loader />
        </motion.div>
      )}
    </AnimatePresence>
  );
};
export default App;
