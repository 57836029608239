import { SyliusBrand, MappedSyliusBrand } from '@swibeco/types';
import { getTranslationsForLocale } from './getTranslationsForLocale';

export const getMappedBrand = (
  brand: SyliusBrand,
  locale: string
): MappedSyliusBrand => ({
  ...brand,
  translationsMap: getTranslationsForLocale(locale, brand.translations),
});
