import { styled } from '@swibeco/shared';

export const SearchItemWrapper = styled.div`
  padding: 6px 12px;
  display: flex;
  color: ${({ theme }) => theme.colors.primary.dark};
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin: 0 6px;
  &:hover {
    color: ${({ theme }) => theme.colors.primary.dark};
    background-color: ${({ theme }) => theme.colors.default.main};
  }
`;

export const ProductImageWrapper = styled.div`
  .search-image-wrapper {
    width: 140px;
    height: 82px;
  }
`;
