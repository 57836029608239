import React from 'react';
import { Caption, Link } from '@swibeco/ui';
import { ECOMMERCE_ROOT } from '@swibeco/shared';
import { Product } from '@swibeco/types';
import * as Styles from './styles/SearchItem.styles';
import ProductImage from '../../ProductItem/ProductImage';

const SearchItem = ({
  product,
  toggleSearchResults,
}: {
  product: Product;
  toggleSearchResults: () => void;
}) => (
  <Link
    to={`${ECOMMERCE_ROOT}/product/${
      product?.defaultVariantData.slug || product?.slug
    }`}
    onClick={toggleSearchResults}
  >
    <Styles.SearchItemWrapper>
      <Styles.ProductImageWrapper>
        <ProductImage
          productImageContainerClass="search-image-wrapper"
          product={product}
          displayBrand={false}
          imageHasMargin
          isFlash={false}
          isProductNew={false}
        />
      </Styles.ProductImageWrapper>
      <Caption w="full">{`${product?.brand.translationsMap.name} - ${product?.name}`}</Caption>
    </Styles.SearchItemWrapper>
  </Link>
);

export default SearchItem;
