const getUserAgent = (window: Record<string, any>): string => {
  const navigator = window?.navigator;
  const userAgent = navigator?.userAgent?.toLowerCase();

  return userAgent;
};

export const isAndroidWebView = (window: Record<string, any>): boolean => {
  const userAgent = getUserAgent(window);

  return !!userAgent.includes('wv');
};

export const isIosWebView = (window: Record<string, any>): boolean => {
  const userAgent = getUserAgent(window);

  const safari = /safari/.test(userAgent);
  const ios = /iphone|ipod|ipad/.test(userAgent);

  return ios ? !window?.navigator?.standalone && !safari : false;
};

export const isNativeIos = (window: Record<string, any>): boolean => {
  const userAgent = getUserAgent(window);

  const ios = /iphone|ipod|ipad/.test(userAgent);

  return ios ?? false;
};
