import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { SecurityRouter } from '@swibeco/security-web';
import { ErrorPage, CoreRouter, PageViewDataLayerHOC } from '@swibeco/core-web';
import { Loader } from '@swibeco/ui';
import { useRedirectAfterLogin } from '@swibeco/security';
import { LOADING_PAGE_AFTER_PAYMENT } from '@swibeco/shared';

const Router = () => {
  useRedirectAfterLogin();
  return (
    <PageViewDataLayerHOC>
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route
            path="/"
            exact
            render={() => {
              window.location.href = '/';

              return null;
            }}
          />
          {/* If the path changes, go change the path in the sidebar menu as well */}
          <Route path="/core/" component={CoreRouter} />
          <Route path="/security/" component={SecurityRouter} />
          <Route
            path={`${LOADING_PAGE_AFTER_PAYMENT}/:checkoutResult?`}
            component={Loader}
          />
          <Route path={['/error', '*']} component={ErrorPage} />
        </Switch>
      </Suspense>
    </PageViewDataLayerHOC>
  );
};

export default Router;
