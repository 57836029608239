import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { actions, selectors as coreSelectors } from '@swibeco/core';
import { selectors as securitySelectors } from '@swibeco/security';
import { UserOnboardedType } from '@swibeco/types';
import {
  BASKET_PAGE,
  ECOMMERCE_HOME,
  PRODUCT_PAGE_ROOT,
  PROFILE,
  UNIVERSE_ROOT,
} from '@swibeco/shared';
import * as Styles from './styles/HamburgerMenu.styles';

const HamburgerMenu = ({ isUserOnboarded }: UserOnboardedType) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(securitySelectors.isAuthenticated);
  const menuIsVisible = useSelector(coreSelectors.isMenuVisible);

  const isUniversePage = useRouteMatch(UNIVERSE_ROOT);
  const isHomePage = useRouteMatch(ECOMMERCE_HOME);
  const isProductPage = useRouteMatch(PRODUCT_PAGE_ROOT);
  const isBasketPage = useRouteMatch(BASKET_PAGE);
  const isUserPage = useRouteMatch(PROFILE);
  const shouldDisplay =
    isUniversePage || isHomePage || isProductPage || isBasketPage || isUserPage;

  return shouldDisplay && isAuthenticated && isUserOnboarded ? (
    <Styles.MenuIcon
      onClick={(e) => {
        e.stopPropagation();
        dispatch(actions.toggleMenu());
      }}
      className="d-flex flex-column align-items-center justify-content-end d-lg-none pr-3"
      data-testid="menu-icon"
    >
      <Styles.HamburgerMenuIcon>
        <Styles.Span toggled={menuIsVisible} />
        <Styles.Span toggled={menuIsVisible} />
        <Styles.Span toggled={menuIsVisible} />
      </Styles.HamburgerMenuIcon>
    </Styles.MenuIcon>
  ) : null;
};

export default HamburgerMenu;
