import { useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  AvailableSearchParamValues,
  ProductBreadcrumbsParams,
} from '@swibeco/types';
import { useDraftModeContext, draftModeActions } from '@swibeco/ecommerce';

const filterParams = [
  'brands',
  'offersFrom',
  'offerType',
  'buyableWithSwipoints',
  'reimbursable',
];
type SetParamsOptions = Partial<{ replace: boolean }>;

interface IUseRouterParamsReturnType {
  urlParams: URLSearchParams;
  params: Partial<AvailableSearchParamValues> &
    Partial<ProductBreadcrumbsParams>;
  selectedParams: number;
  setParams: (
    newParams: Partial<AvailableSearchParamValues> &
      Partial<ProductBreadcrumbsParams>,
    options?: SetParamsOptions
  ) => void;
  clearParams: (param?: string) => void;
  applyDraftParams: () => void;
}

const generateParams = (
  newParams: Partial<AvailableSearchParamValues>,
  urlParams: URLSearchParams
) => {
  const keys = Object.keys(newParams);
  keys.forEach((key) => {
    if (Array.isArray(newParams[key])) {
      const params = newParams[key]
        .map((_v: { label: string; value: string }) => _v.value)
        .join(',');
      urlParams.set(key, params);
    } else {
      urlParams.set(key, newParams[key]);
    }
  });
  return urlParams;
};

const useRouterParams = (): IUseRouterParamsReturnType => {
  const location = useLocation();
  const history = useHistory();

  const [{ isDraftMode, generatedParams }, dispatchDraftMode] =
    useDraftModeContext();

  const urlParams = useMemo(
    () => new URLSearchParams(isDraftMode ? generatedParams : location.search),
    [location.search, generatedParams, isDraftMode]
  );

  const setParams = (
    newParams: Partial<AvailableSearchParamValues>,
    options: SetParamsOptions = {}
  ) => {
    if (isDraftMode) {
      dispatchDraftMode(
        draftModeActions.saveGeneratedParams(
          `?${generateParams(
            newParams,
            new URLSearchParams(generatedParams)
          ).toString()}`
        )
      );
      return;
    }

    if (options.replace) {
      const replaceParams = new URLSearchParams();
      history.replace(
        `?${generateParams(newParams, replaceParams).toString()}`
      );
      return;
    }
    history.push(`?${generateParams(newParams, urlParams).toString()}`);
  };

  const clearParams = (param?: string) => {
    const searchParams = new URLSearchParams(history.location.search);
    if (param) {
      searchParams.delete(param);
    } else {
      filterParams.map((p) => searchParams.delete(p));
    }
    const newSearch = searchParams.toString();
    if (isDraftMode) {
      dispatchDraftMode(draftModeActions.saveGeneratedParams(`?${newSearch}`));
      return;
    }

    history.push({
      pathname: history.location.pathname,
      search: newSearch,
    });
  };

  const applyDraftParams = () => {
    history.push({
      pathname: history.location.pathname,
      search: generatedParams,
    });
  };

  const params = useMemo(() => {
    const paramsObj: Partial<AvailableSearchParamValues> = {};
    urlParams.forEach((value, key) => {
      if (value.includes(',')) {
        paramsObj[key] = value.split(',').map((v) => ({
          label: v.charAt(0).toUpperCase() + v.slice(1),
          value: v,
        }));
        return;
      }
      if (value === 'true' || value === 'false') {
        paramsObj[key] = value === 'true';
        return;
      }
      if (filterParams.includes(key)) {
        paramsObj[key] = [
          {
            label: value.charAt(0).toUpperCase() + value.slice(1),
            value,
          },
        ];
      } else {
        paramsObj[key] = value;
      }
    });
    return paramsObj;
  }, [urlParams]);
  let selectedParams = 0;
  Object.keys(params).forEach((key) => {
    if (key === 'buyableWithSwipoints') {
      if (params[key]) {
        selectedParams += 1;
      }
    } else if (key === 'reimbursable') {
      if (params[key]) {
        selectedParams += 1;
      }
    } else if (key === 'hasNewOffers') {
      if (params[key]) {
        selectedParams += 1;
      }
    } else if (key !== 'sort') {
      selectedParams += params[key].length || 0;
    }
  });
  return {
    urlParams,
    selectedParams,
    setParams,
    clearParams,
    params,
    applyDraftParams,
  };
};

export default useRouterParams;
