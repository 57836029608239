/* eslint-disable react/jsx-one-expression-per-line */
import { useTheme } from '@emotion/react';
import React, { forwardRef, useEffect, useState } from 'react';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { InputProps, RefReturn } from '../types';
import {
  ErrorPhoneLabel,
  PhoneInputContainer,
  PhoneLabel,
  TelInputContainer,
  SpecialPlaceHolder,
} from './PhoneInput.styles';

const customPlaceholder = (
  country: string,
  placeholder: string | undefined
) => {
  switch (country) {
    case 'it':
      return '+44 76 100 01 02';
    case 'fr':
      return '+33 6 01 02 03 04';
    case 'ch':
      return '+41 76 100 01 02';
    case 'de':
      return '+49 3 01 02 03 04';
    default:
      return placeholder || '+41 76 100 01 02';
  }
};

const PhoneNumberInput = forwardRef(
  (
    {
      as,
      state,
      errorString = '',
      noMargin,
      required = false,
      border,
      colorLabel,
      label,
      control,
      controller,
      clearErrors,
      trigger,
      placeholder,
      errorTextAlign = 'left',
      name = '',
      value = '',
      bg,
      setValue,
      formState,
      country = 'ch',
      phonePrefix = '41',
      shouldBeGreyAtMount,
      setPrefix,
      format,
      hideIndicator,
      labelLetterSpacing,
    }: InputProps,
    ref: RefReturn
  ) => {
    const Controller = control && controller;
    const theme = useTheme();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [phoneValue, setPhone] = useState<any>({
      phone: value,
      country: null,
    });
    const [indicator, setIndicator] = useState<string>('+.. .. ... .. ..');
    const [color] = useState(
      shouldBeGreyAtMount && theme.colors ? theme.colors.default.black : ''
    );
    useEffect(() => {
      if (value && country && setValue) {
        let phoneNumber = '';
        if (typeof value === 'string' && value.startsWith(phonePrefix)) {
          phoneNumber = value.substring(phonePrefix.length);
        }
        setValue(name, phoneNumber);
        if (setPrefix) {
          setValue(setPrefix, phonePrefix);
        }
        if (!formState?.isDirty) {
          setPhone({ phone: phoneNumber, country });
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [country, value, formState?.isDirty, setValue, name, phonePrefix]);
    const inputProps = {
      id: name,
      name,
      required,
      autoComplete: 'none',
      'data-testid': 'input-id',
      'aria-label': label,
      ...ref,
    };
    const inputStyle = {
      backgroundColor:
        bg === 'grey' ? theme?.colors?.default.middle : undefined,
      color,
    };
    const customFormatter = (
      phone: string,
      format = '+... ... ... ... ... ..'
    ) => {
      let formatArray = format?.split('');
      const inputArray = phone?.split('');
      let stop = inputArray.length;
      formatArray = formatArray.map((e: string) => {
        if (e === '.' && stop > 0) {
          // eslint-disable-next-line no-param-reassign
          e = inputArray[inputArray.length - stop];
          // eslint-disable-next-line no-plusplus
          stop--;
        }
        return e;
      });
      const newFormat = formatArray.join('');
      setIndicator(newFormat);
      return newFormat;
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChange = (values: { phone: string; data: any }) => {
      const newValues = { ...values };
      setPhone({
        phone: newValues.phone.replace(newValues.data.dialCode, ''),
      });
      setValue?.(name, newValues.phone.replace(newValues.data.dialCode, ''), {
        shouldValidate: true,
        shouldDirty: true,
      });
      if (format) {
        const newFormat = customFormatter(
          newValues.phone,
          newValues.data?.format
        );

        setValue?.(
          format,
          newFormat
            .replace(newValues.data.dialCode, '')
            .replaceAll(/[ +\-()]/g, '')
        );
      }
      if (setPrefix) {
        setValue?.(setPrefix, newValues?.data.dialCode, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
      if (newValues?.phone !== '') {
        clearErrors(name);
      } else {
        trigger(name);
      }
    };
    return (
      <PhoneInputContainer
        as={as}
        state={state}
        error={!!errorString}
        noMargin={noMargin}
      >
        {control ? (
          <Controller
            defaultValue=""
            render={() => (
              <>
                <PhoneLabel
                  state={state}
                  isRequired={required}
                  letterSpacing={labelLetterSpacing}
                  border={border}
                  data-testid="phone-input-label"
                  color={colorLabel}
                  htmlFor={name}
                >
                  {label}
                </PhoneLabel>
                <TelInputContainer>
                  <PhoneInput
                    placeholder={customPlaceholder(country, placeholder)}
                    country={phoneValue?.countryCode || country}
                    inputProps={inputProps}
                    inputStyle={inputStyle}
                    value={value as string}
                    preferredCountries={['ch', 'de', 'fr', 'it']}
                    onChange={(phone, data) => {
                      handleChange({ phone, data });
                    }}
                    onMount={(value) => {
                      setIndicator(`+${value}`);
                    }}
                    masks={{ ma: '(.) ..-..-..-..', li: '(.) ..-..-..' }}
                  />
                  {!hideIndicator && (
                    <SpecialPlaceHolder>
                      {indicator.includes('.') && indicator}
                    </SpecialPlaceHolder>
                  )}
                  {errorString && (
                    <ErrorPhoneLabel
                      data-testid="phone-input-error"
                      errorTextAlign={errorTextAlign}
                    >
                      {errorString}
                    </ErrorPhoneLabel>
                  )}
                </TelInputContainer>
              </>
            )}
            name={name}
            control={control}
            data-testid="input-id"
          />
        ) : (
          <>
            <PhoneLabel
              state={state}
              isRequired={required}
              letterSpacing={labelLetterSpacing}
              border={border}
              data-testid="phone-input-label"
              color={colorLabel}
              htmlFor={name}
            >
              {label}
            </PhoneLabel>
            <TelInputContainer>
              <PhoneInput
                placeholder={customPlaceholder(country, placeholder)}
                country={phoneValue?.countryCode || country}
                inputProps={inputProps}
                inputStyle={inputStyle}
                value={value as string}
                preferredCountries={['ch', 'de', 'fr', 'it']}
                onChange={(phone, data: CountryData) => {
                  handleChange({ phone, data });
                }}
                onMount={(value) => {
                  setIndicator(`+${value}`);
                }}
              />
              {!hideIndicator && (
                <SpecialPlaceHolder>
                  {indicator.includes('.') && indicator}
                </SpecialPlaceHolder>
              )}
              {errorString && (
                <ErrorPhoneLabel
                  data-testid="phone-input-error"
                  errorTextAlign={errorTextAlign}
                >
                  {errorString}
                </ErrorPhoneLabel>
              )}
            </TelInputContainer>
          </>
        )}
      </PhoneInputContainer>
    );
  }
);

export default PhoneNumberInput;
