import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Col } from 'reactstrap';
import { selectors, useCurrentUser } from '@swibeco/security';
import { GlobalSelectors, isNativeIos } from '@swibeco/shared';
import { selectors as coreSelectors, useIsTablet } from '@swibeco/core';
import Logo from '../Logo/Logo';
import LanguageSwitcher from './LanguageSwitcher';
import MyBasket from './MyBasket';
import * as Styles from './styles/Header.styles';
import UserProfile from './UserProfile/UserProfile';
import Wishlist from './Wishlist';
import HRAdmin from './HRAdmin';
import HamburgerMenu from './HamburgerMenu';
import Search from './Search/Search';
import AxaHeaderBanner from './AxaHeaderBanner/AxaHeaderBanner';

const Header = () => {
  const isAuthenticated = useSelector(selectors.isAuthenticated);
  const isHeaderFull = useSelector(coreSelectors.isHeaderFull);
  const hasHRAdminRights = useSelector(GlobalSelectors.user.hasHRAdminRights);
  const { user, clearUser } = useCurrentUser();
  const isTablet = useIsTablet();
  const isUserOnboarded = useMemo(() => user?.accountCompleted, [user]);
  const authenticatedOnboardedUser = useMemo(
    () => isAuthenticated && isUserOnboarded,
    [isAuthenticated, isUserOnboarded]
  );

  return (
    <>
      <AxaHeaderBanner />
      <Styles.Wrapper id="header-wrapper">
        <Styles.Header className="header">
          <Styles.IconsWrapper
            className={`h-100 col-10 offset-1 px-0 d-flex pb-lg-0 ${
              authenticatedOnboardedUser
                ? 'align-items-end'
                : 'align-items-center pb-0'
            }`}
          >
            <Col xs={6} md={4} className="d-inline-flex pl-0 mb-1">
              {/**
               * Hamburger menu hidden to navigate easily between Angular and React thanks to an horizontal navbar
               * @todo
               * 1. Prevent displaying menu also on error page
               * 2. Check if the Hamburger menu is needed on specific project
               */}
              <HamburgerMenu isUserOnboarded={isUserOnboarded} />
              <Logo className="mx-0 float-left" />
            </Col>

            <Col
              xs={6}
              md={8}
              className="d-flex align-items-center justify-content-end pr-0"
            >
              {authenticatedOnboardedUser && isHeaderFull && (
                <>
                  <Search />
                  {hasHRAdminRights && !isNativeIos(window) && <HRAdmin />}
                </>
              )}
              {authenticatedOnboardedUser &&
                isHeaderFull &&
                !isTablet &&
                !isNativeIos(window) && (
                  <Wishlist isAuthenticated={isAuthenticated} />
                )}
              {authenticatedOnboardedUser && isHeaderFull && (
                <MyBasket isAuthenticated={isAuthenticated} />
              )}
              {authenticatedOnboardedUser && isHeaderFull && (
                <UserProfile userData={user as any} clearUser={clearUser} />
              )}
              <LanguageSwitcher show={!isAuthenticated || !isUserOnboarded} />
            </Col>
          </Styles.IconsWrapper>
        </Styles.Header>
      </Styles.Wrapper>
    </>
  );
};

export default Header;
