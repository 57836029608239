import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import {
  AxiosError,
  FORGOT_PASSWORD,
  JWT_STORAGE,
  LOADING_PAGE_AFTER_PAYMENT,
  LOGIN,
  REQUEST_INVITATION,
  RESET_PASSWORD,
} from '@swibeco/shared';
import authenticationApi from '../authenticationApi';
import { actions } from '../securitySlice';
import { useQueryOptionsType } from './types';

const shouldRedirect = () =>
  !window.location.pathname.includes(`/v2${LOGIN}`) &&
  !window.location.pathname.includes(`/v2${REQUEST_INVITATION}`) &&
  !window.location.pathname.includes(`/v2${FORGOT_PASSWORD}`) &&
  !window.location.pathname.includes(`/v2${RESET_PASSWORD}`) &&
  !window.location.pathname.includes(LOADING_PAGE_AFTER_PAYMENT);

const useRefreshToken = (options: useQueryOptionsType = null) => {
  const dispatch = useDispatch();
  const query = useQuery({
    queryKey: ['refresh-token'],
    queryFn: authenticationApi.refreshToken,
    retry: false,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    ...options,
  });
  useEffect(() => {
    if (query?.isSuccess) {
      JWT_STORAGE.token = query.data;
      dispatch(actions.userLoggedIn());
    }
  }, [query?.isSuccess, dispatch, query.data]);
  useEffect(() => {
    if (query?.isError) {
      dispatch(actions.userLoggedOut());
      if (
        (query.error as AxiosError).response?.status === 401 &&
        shouldRedirect()
      ) {
        window.location.assign(`/v2${LOGIN}`);
      }
    }
  }, [query?.isError, dispatch, query?.error]);
  return query;
};

export default useRefreshToken;
