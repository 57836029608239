/**
 * REACT
 * Global
 */
export const ERROR = '*';
export const ERROR_PAGE = '/error';

/**
 * REACT
 * Security / Login
 */
export const SECURITY = '/security';
export const LOGIN = '/security/login';
export const REQUEST_INVITATION = '/security/request-invitation';
export const REQUEST_MFA = '/security/request-mfa';
export const FORGOT_PASSWORD = '/security/forgot-password';
export const RESET_PASSWORD = '/security/reset-password';

/**
 * REACT
 * Ecommerce
 */
export const HOME = '/';
export const ECOMMERCE_ROOT = '/core';
export const ECOMMERCE_HOME = '/core/home';
export const ECOMMERCE_CATEGORY_ROOT = '/core/universe/category';
export const ECOMMERCE_BRAND_ROOT = '/core/universe/brand';
export const ORDERS = '/core/user/orders';
// SEARCH RESULTS
export const SEARCH_RESULTS = '/core/deals/:context/:contextValue?';
export const SEARCH_RESULTS_ROOT = '/core/deals/search';
// PROFILE
export const PROFILE = '/core/user/profile';
// SWIPOINTS
export const SWIPOINTS = '/core/wallet/swipoints';
// CHECKOUT
export const CHECKOUT = '/core/checkout';
export const CHECKOUT_CONFIRMATION = '/core/checkout-confirmation';
// UNIVERSE
export const UNIVERSE_ROOT = '/core/universe';
export const UNIVERSE =
  '/core/universe/:context/:contextValue?/:subSection?/:values*';
// PRODUCT
export const PRODUCT_PAGE = '/core/product/:product';
export const PRODUCT_PAGE_ROOT = '/core/product';
// BASKET
export const BASKET_PAGE = '/core/basket';
// PLUS LANDING PAGE
export const PLUS_LANDING_PAGE = '/core/plus';
// FAVOURITES PAGE
export const FAVOURITES_PAGE = '/core/favourites';

/**
 * REACT
 * Onboarding
 */
export const REACT_ONBOARDING_DOMAIN = 'onboarding.';
export const REGISTER = '/register/:token';
export const JOIN = '/join/:token';
export const JOIN_SUCCESS = '/join/success';

// Loading page for mobile payment process
export const LOADING_PAGE_AFTER_PAYMENT = '/mobile-checkout';
