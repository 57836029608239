import {
  actions,
  selectors as coreSelectors,
  useIsTablet,
  usePlatform,
  useWindowSize,
} from '@swibeco/core';
import {
  isCategoryPage,
  useUniverseParams,
  syliusApi,
} from '@swibeco/ecommerce';
import {
  UNIVERSE_ROOT,
  ECOMMERCE_CATEGORY_ROOT,
  DEFAULT_SORT,
  GlobalSelectors,
  useColorVariant,
  isNativeIos,
  getTranslationsForLocale,
} from '@swibeco/shared';
import {
  MainContentWrapper,
  UnderlayMask,
  useScrollBlock,
} from '@swibeco/shared-web';
import { MappedSyliusCategory, SyliusCategory } from '@swibeco/types';
import { HoverBehavior, Popover } from '@swibeco/ui';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useDisclosure } from '@chakra-ui/react';
import { useCurrentCompany } from '@swibeco/security';
import { getCountryCodeByLocale } from '@swibeco/shared/lib/utils/getTranslationsForLocale';
import { useHistory } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import flashDeals from '../../assets/images/flash-white.png';
import { ReactComponent as InhouseDealsIcon } from '../../assets/images/inhouse-deals.svg';
import useRouterParams from '../../hooks/useRouterParams';
import MenuLink from './MenuLink/MenuLink';
import * as Styles from './TopCategoryMenu.styles';
import { TopCategoryMenuSkeletonLoading } from './TopCategoryMenuSkeletonLoading';

type WithCategory = {
  category: MappedSyliusCategory;
};

function createDataLayerEvent(
  environment: string,
  categoryName: string,
  isSubcategory?: boolean
) {
  const eventName = isSubcategory ? 'click_subuniverse' : 'click_universe';
  const eventNameData = isSubcategory ? 'subuniverse_name' : 'universe_name';
  TagManager.dataLayer({
    dataLayer: {
      event: eventName,
      environment,
      [eventNameData]: categoryName,
    },
  });
}

/**
 * @Refactor : onClick event not used
 */
const Subcategories = ({
  category,
  onClick,
}: WithCategory & { onClick: () => void }) => {
  const { t } = useTranslation();
  const mainColorVariant = useColorVariant('primary', 'main');
  const environment = usePlatform(window);

  if (!category?.optimizedChildren || category.optimizedChildren.length === 0) {
    return null;
  }

  return (
    <>
      <div className="d-flex align-items-center">
        <Styles.ListTitle important>
          {t('core.ecommerce.homepage.top_category.categories')}
        </Styles.ListTitle>
        {category.slug && (
          <HoverBehavior className="ml-2" color={mainColorVariant}>
            <Styles.SeeAllLink
              type={2}
              color={mainColorVariant}
              to={`${ECOMMERCE_CATEGORY_ROOT}/${category.slug}?sort=${DEFAULT_SORT}`}
              onClick={() => {
                onClick();
                createDataLayerEvent(environment, category.name);
              }}
            >
              {t('core.ecommerce.homepage.top_category.see_all')}
            </Styles.SeeAllLink>
          </HoverBehavior>
        )}
      </div>
      <Styles.CategoriesList>
        {category?.optimizedChildren?.slice(0, 6).map((subCategory) => (
          <div
            className="col-3 col-md-3 p-0"
            key={subCategory.id}
            style={{
              minWidth: '250px',
            }}
          >
            <Styles.CategoryItem
              to={`${ECOMMERCE_CATEGORY_ROOT}/${category?.slug}/${subCategory?.slug}?sort=${DEFAULT_SORT}`}
              onClick={() => {
                onClick();
                createDataLayerEvent(environment, subCategory.name, true);
              }}
            >
              {subCategory.name}
            </Styles.CategoryItem>
          </div>
        ))}
      </Styles.CategoriesList>
    </>
  );
};

interface TopCategoryMenuProps {
  reset?: (values?: Record<string, any>) => void;
}

const TopCategoryMenu = ({ reset }: TopCategoryMenuProps) => {
  const isTablet = useIsTablet();
  const universeParams = useUniverseParams();
  const { clearParams, params } = useRouterParams();
  const paramsCategory = isCategoryPage(universeParams)
    ? universeParams?.category
    : '';
  const queryClient = useQueryClient();
  const locale = useSelector(coreSelectors.getLocale);
  const hasHRAdminRights = useSelector(GlobalSelectors.user.hasHRAdminRights);
  const [hoveredCategory, setHoveredCategory] =
    useState<MappedSyliusCategory | null>(null);
  const prevLocale = useRef<string>();
  const { data: categories, isLoading } = useQuery<SyliusCategory[]>({
    queryKey: ['sylius-taxons-with-product-variants', locale],
    queryFn: () => syliusApi.getTaxonsWithProductVariants(locale),
  });
  const mappedCategories = useMemo(() => {
    // recursively go through all the children and map the translations
    const mapTranslations = (category: SyliusCategory) => {
      const mappedCategory: MappedSyliusCategory = {
        ...category,
        translationsMap: getTranslationsForLocale(
          locale,
          category.translations
        ),
        optimizedChildren: category.optimizedChildren.map((optimizedChild) =>
          mapTranslations(optimizedChild)
        ),
        optimizedVariants: category.optimizedVariants.map((variant) => ({
          ...variant,
          translationsMap: getTranslationsForLocale(
            locale,
            variant.translations
          ),
        })),
      };
      return mappedCategory;
    };
    return categories?.map((category) => mapTranslations(category));
  }, [categories, locale]);
  const dispatch = useDispatch();
  const { t, ready } = useTranslation();
  const { allowScroll, blockScroll } = useScrollBlock();
  const previousScrollPosRef = useRef(window.scrollY);
  const [showDesktopMenu, setShowDesktopMenu] = useState(true);
  const subcategoriesMenuIsVisible = useSelector(coreSelectors.isMenuVisible);
  const { company } = useCurrentCompany();
  const { isOpen, onOpen } = useDisclosure();
  const environment = usePlatform(window);
  const history = useHistory();
  const staticMappedCategories = useRef<MappedSyliusCategory[] | undefined>(
    mappedCategories
  );
  useEffect(() => {
    if (!prevLocale.current) {
      prevLocale.current = locale;
    }
    // if locale changes, redirect to category (and subcategory if available) corresponding to that locale
    if (locale !== prevLocale.current) {
      const prevCountryCode = getCountryCodeByLocale(prevLocale.current);
      const countryCode = getCountryCodeByLocale(locale);
      const currentCategory = staticMappedCategories.current?.find(
        (category) =>
          category.translations[prevCountryCode].slug === paramsCategory
      );
      const subCategorySlug =
        universeParams.context === 'category'
          ? universeParams.subCategory
          : undefined;
      const subCategory = currentCategory?.optimizedChildren?.find(
        (subCategory) =>
          subCategory.translations[prevCountryCode].slug === subCategorySlug
      );
      if (currentCategory) {
        if (subCategory) {
          history.push(
            `${ECOMMERCE_CATEGORY_ROOT}/${currentCategory.translations[countryCode].slug}/${subCategory?.translations[countryCode].slug}`
          );
        } else {
          history.push(
            `${ECOMMERCE_CATEGORY_ROOT}/${currentCategory.translations[countryCode].slug}`
          );
        }
        staticMappedCategories.current = mappedCategories;
      }
      prevLocale.current = locale;
    }
    if (!staticMappedCategories.current && mappedCategories) {
      staticMappedCategories.current = mappedCategories;
    }
  }, [locale, mappedCategories, paramsCategory, universeParams, history]);

  const hideMenuAndResetHover = useCallback(
    (forceClose?: boolean) => () => {
      if (forceClose) {
        dispatch(actions.hideMenu());
      } else if (!isTablet) {
        dispatch(actions.hideMenu());
      }
      setHoveredCategory(null);
    },
    [dispatch, isTablet]
  );

  const hideMenuResetHoverAndClose = useCallback(
    () => hideMenuAndResetHover(true),
    [hideMenuAndResetHover]
  );

  const openMenu = (category: MappedSyliusCategory | null) => {
    if (category && category?.optimizedChildren?.length > 0) {
      dispatch(category ? actions.showMenu() : actions.hideMenu());
      setHoveredCategory(category);
      onOpen();
    }
  };

  useEffect(() => {
    if (subcategoriesMenuIsVisible && isTablet) {
      blockScroll();
    } else {
      allowScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subcategoriesMenuIsVisible]);
  useEffect(
    () => () => {
      allowScroll();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  useEffect(() => {
    hideMenuAndResetHover();
    const translateItem =
      mappedCategories?.find(
        (category) => category.slug === hoveredCategory?.slug
      ) || null;
    setHoveredCategory(translateItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  const categoryDisplayLimit = 10;
  const resetFilters = (categoryName: string, isSubcategory?: boolean) => {
    createDataLayerEvent(environment, categoryName, isSubcategory);
    clearParams();
    reset?.({
      brands: [],
      offerType: [],
      offersFrom: false,
      buyableWithSwipoints: false,
      sort: {
        value: DEFAULT_SORT,
        label: t('core.ecommerce.universe.pertinence_asc'),
      },
    });
    queryClient.refetchQueries({ queryKey: ['all-deals'] });
    queryClient.refetchQueries({ queryKey: ['general-deals'] });
    queryClient.refetchQueries({ queryKey: ['flash-deals'] });
    queryClient.refetchQueries({ queryKey: ['popular-deals'] });
  };

  // handles hiding/showing category menu on scroll
  useEffect(() => {
    const onScrollToggleMenu = () => {
      const currentScrollPos = window.scrollY;

      if (currentScrollPos <= previousScrollPosRef.current) {
        setShowDesktopMenu(true);
      } else if (currentScrollPos > 170) {
        setShowDesktopMenu(false);
        hideMenuAndResetHover()();
      }
      previousScrollPosRef.current = currentScrollPos;
    };
    if (!isTablet) {
      window.addEventListener('scroll', onScrollToggleMenu);
    }
    return () => {
      window.removeEventListener('scroll', onScrollToggleMenu);
    };
  }, [
    isTablet,
    setShowDesktopMenu,
    hideMenuAndResetHover,
    previousScrollPosRef,
  ]);

  const { width } = useWindowSize();
  const popperWidth = width * 0.84 + 36;
  const spaceLeft = width - (popperWidth > 1517 ? 1517 : popperWidth);

  if (isLoading || !ready) return <TopCategoryMenuSkeletonLoading />;

  return mappedCategories && mappedCategories.length ? (
    <>
      <Styles.TopCategoryMenuWrapper
        menuIsVisible={subcategoriesMenuIsVisible}
        data-testid="top-categorie"
        showDesktopMenu={showDesktopMenu}
      >
        <MainContentWrapper>
          <Styles.TopCategoryMenu>
            <Styles.TopCategoryMenuItem>
              <Styles.TopCategoryMenuLink
                to={`${ECOMMERCE_CATEGORY_ROOT}/flash-deals`}
                onClick={() => {
                  resetFilters('Flash deals');
                  hideMenuResetHoverAndClose();
                }}
                noFilter
              >
                <MenuLink
                  icon={flashDeals}
                  text={t('core.homepage.categories.flash_deals')}
                  id="flash"
                  isActive={paramsCategory === 'flash-deals'}
                />
              </Styles.TopCategoryMenuLink>
            </Styles.TopCategoryMenuItem>
            {company?.has_inhouse_deals && (
              <Styles.TopCategoryMenuItem>
                <Styles.TopCategoryMenuLink
                  to={`${UNIVERSE_ROOT}/mycompanybenefits`}
                  onClick={() => {
                    resetFilters('My Company benefits');
                    hideMenuResetHoverAndClose();
                  }}
                  noFilter
                >
                  <MenuLink
                    iconComponent={<InhouseDealsIcon />}
                    text={t('core.homepage.categories.inhousedeals')}
                    id="inhousedeals"
                    isActive={
                      params.c === 'mycompanybenefits' ||
                      universeParams.context === 'mycompanybenefits'
                    }
                  />
                </Styles.TopCategoryMenuLink>
              </Styles.TopCategoryMenuItem>
            )}
            {mappedCategories
              ?.slice(0, categoryDisplayLimit)
              .map((category: MappedSyliusCategory) => {
                const hasSubCategories =
                  category && category?.optimizedChildren?.length > 0;
                return (
                  <Popover
                    key={category.id}
                    isOpen={
                      !isTablet &&
                      subcategoriesMenuIsVisible &&
                      hoveredCategory?.id === category.id &&
                      isOpen
                    }
                    modifiers={[
                      {
                        name: 'computeStyles',
                        options: {
                          roundOffsets: ({ y }: { y: number }) => ({
                            x: spaceLeft / 2,
                            y,
                          }),
                        },
                      },
                    ]}
                    showArrow={false}
                    isLazy
                    lazyBehavior="keepMounted"
                    gutter={0}
                    popoverContentProps={{
                      width: '100%',
                      onMouseEnter: () => openMenu(category),
                      // onMouseLeave: hideMenuAndResetHover,
                      onFocus: () => openMenu(category),
                      onBlur: hideMenuAndResetHover(false),
                      animate: {
                        opacity:
                          !isTablet &&
                          subcategoriesMenuIsVisible &&
                          hoveredCategory?.id === category.id &&
                          isOpen
                            ? 1
                            : 0,
                        visibility:
                          !isTablet &&
                          subcategoriesMenuIsVisible &&
                          hoveredCategory?.id === category.id &&
                          isOpen
                            ? 'visible'
                            : 'hidden',
                        transition: {
                          duration: 0,
                        },
                      },
                    }}
                    popoverTriggerProps={{
                      onMouseEnter: () => openMenu(category),
                      onMouseLeave: hideMenuAndResetHover(false),
                      onFocus: () => openMenu(category),
                      onBlur: hideMenuAndResetHover(false),
                    }}
                    popoverTrigger={
                      <Styles.TopCategoryMenuItem key={category.slug}>
                        {category.redirectLink ? (
                          <Styles.TopCategoryMenuLink
                            to={category.redirectLink}
                            onClick={() => {
                              resetFilters(category.name);
                              openMenu(hasSubCategories ? category : null);
                            }}
                          >
                            <MenuLink
                              iconComponent={
                                category.images?.length ? (
                                  <img
                                    src={category.images[0].path}
                                    alt="icon"
                                  />
                                ) : (
                                  ''
                                )
                              }
                              text={category.name}
                              id={`cat-${category.slug}`}
                              isActive={
                                category.slug === paramsCategory ||
                                category.slug === params.c
                              }
                            />
                          </Styles.TopCategoryMenuLink>
                        ) : (
                          <Styles.TopCategoryMenuLink
                            to={`${ECOMMERCE_CATEGORY_ROOT}/${category.slug}?sort=${DEFAULT_SORT}`}
                            onClick={() => {
                              resetFilters(category.name);
                              hideMenuResetHoverAndClose();
                            }}
                          >
                            <MenuLink
                              iconComponent={
                                category.images?.length ? (
                                  <img
                                    src={category.images[0].path}
                                    alt="icon"
                                  />
                                ) : (
                                  ''
                                )
                              }
                              text={category.name}
                              id={`cat-${category.slug}`}
                              isActive={
                                category.slug === paramsCategory ||
                                category.slug === params.c
                              }
                            />
                          </Styles.TopCategoryMenuLink>
                        )}
                      </Styles.TopCategoryMenuItem>
                    }
                  >
                    <Styles.SubCategoryContainer>
                      <Subcategories
                        category={category}
                        onClick={() => {
                          resetFilters(category.name);
                          hideMenuResetHoverAndClose();
                        }}
                      />
                    </Styles.SubCategoryContainer>
                  </Popover>
                );
              })}
            {isTablet && hasHRAdminRights && !isNativeIos(window) && (
              <>
                <Styles.Divider />
                <Styles.TopCategoryMenuItem>
                  <Styles.TopCategoryMenuLink href="/#/admin/company">
                    <MenuLink
                      icon={flashDeals}
                      text="Company settings"
                      id="company-settings"
                    />
                  </Styles.TopCategoryMenuLink>
                </Styles.TopCategoryMenuItem>
              </>
            )}
          </Styles.TopCategoryMenu>
        </MainContentWrapper>
      </Styles.TopCategoryMenuWrapper>
      {subcategoriesMenuIsVisible && (
        <UnderlayMask
          blockScroll={false}
          fullscreen={isTablet}
          onClick={() => dispatch(actions.hideMenu())}
          onMouseEnter={hideMenuAndResetHover(false)}
        />
      )}
    </>
  ) : null;
};

export default TopCategoryMenu;
