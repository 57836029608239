import React, { useState, useEffect, useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  actions,
  useLanguageSwitcher,
  useOnClickOutside,
  usePlatform,
} from '@swibeco/core';
import { syliusApi, UniverseParams } from '@swibeco/ecommerce';
import { HydraResponse, Product } from '@swibeco/types';
import TagManager from 'react-gtm-module';
import { useLocation, useHistory } from 'react-router-dom';
import { Text } from '@swibeco/ui';
import { useDispatch } from 'react-redux';
import {
  generatePageName,
  SEARCH_RESULTS_ROOT,
  useTheme,
} from '@swibeco/shared';
import { Portal } from '@chakra-ui/react';
import * as Styles from './styles/SearchInput.styles';
import SearchItem from './SearchItem';
import { UnderlayMask } from '../../UnderlayMask';

const RESULTS_DISPLAYED = 5;
const MIN_SEARCH_CHARS = 2;

const SearchInput = () => {
  const dispatch = useDispatch();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const [minCharactersNotice, setMinCharactersNotice] = useState(false);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isDelayActive, setIsDelayActive] = useState(false);
  const [searchResults, setSearchResults] = useState<Product[]>([]);
  const { currentLocale } = useLanguageSwitcher();
  const location = useLocation();
  const history = useHistory();
  const pageName = generatePageName(location.pathname);
  const theme = useTheme();
  const environment = usePlatform(window);

  const { mutate, data, isPending, isError } = useMutation({
    mutationFn: (searchData: { query: string; locale: string }) =>
      syliusApi.getHydraProductsListing(
        {
          context: 'search',
          search: searchData.query,
        } as UniverseParams,
        1,
        {},
        searchData.locale
      ),
    onSuccess: (data: HydraResponse<Product[]>) => {
      setSearchResults(data.data.slice(0, RESULTS_DISPLAYED));
      TagManager.dataLayer({
        dataLayer: {
          event: 'view_search_result',
          environment,
          search_term: searchQuery,
          nb_items_results: data.pageInfo.total,
          localisation: pageName,
        },
      });
    },
  });

  useEffect(
    // eslint-disable-next-line consistent-return
    () => {
      if (searchQuery.length >= MIN_SEARCH_CHARS) {
        const delayDebounceFn = setTimeout(() => {
          mutate({ query: searchQuery, locale: currentLocale });
          setIsDelayActive(false);
        }, 250);

        return () => clearTimeout(delayDebounceFn);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchQuery, currentLocale]
  );

  useOnClickOutside(wrapperRef, () => {
    setShowSearchResults(false);
    setMinCharactersNotice(false);
    setSearchQuery('');
    setSearchResults([]);
  });

  const onInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    dispatch(actions.hideMenu());

    if (e.target.value.length < MIN_SEARCH_CHARS) {
      setSearchResults([]);
      setMinCharactersNotice(true);
      return;
    }

    setMinCharactersNotice(false);
  };

  const onInputChange = (e: React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault();

    setSearchQuery(e.target.value);

    if (e.target.value.length >= MIN_SEARCH_CHARS) {
      setMinCharactersNotice(false);
      setShowSearchResults(true);
      setIsDelayActive(true);
      return;
    }

    setShowSearchResults(false);
    setMinCharactersNotice(true);
    setSearchResults([]);
  };

  const allResultsPageUrl = `${SEARCH_RESULTS_ROOT}/${searchQuery}`;

  const hideSearchDropDown = () => {
    setShowSearchResults(false);
    // lose focus from search input
    inputRef.current?.blur();
  };

  const onPressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && searchQuery.length >= MIN_SEARCH_CHARS) {
      hideSearchDropDown();
      history.push(allResultsPageUrl);
    }
  };

  return (
    <Styles.SearchWrapper ref={wrapperRef}>
      <Styles.SearchFieldWrapper>
        <Styles.IconWrapper>
          <FontAwesomeIcon
            icon={faSearch}
            size="sm"
            color={theme.colors.default.black}
          />
        </Styles.IconWrapper>
        <Styles.SearchInput
          data-testid="header-search-input"
          type="search"
          placeholder={t('core.header.search.search_text')}
          onChange={onInputChange}
          onFocus={onInputFocus}
          value={searchQuery}
          onKeyUp={onPressEnter}
          ref={inputRef}
          autoFocus={false}
        />
      </Styles.SearchFieldWrapper>
      {minCharactersNotice && (
        <Styles.SearchResultsPanel
          data-testid="header-search-input-tooltip"
          className="p-2"
        >
          {t('core.header.search.tooltip.min_characters', {
            min: MIN_SEARCH_CHARS,
          })}
        </Styles.SearchResultsPanel>
      )}
      {showSearchResults && (
        <Styles.SearchResultsPanel data-testid="header-search-input-products-list">
          {(isPending || isDelayActive) && (
            <Text className="p-2">{t('core.header.search.searching')}</Text>
          )}
          {searchResults && searchResults.length > 0 && (
            <>
              {searchResults.map((product: Product) => (
                <SearchItem
                  key={product.id}
                  product={product}
                  toggleSearchResults={() => setShowSearchResults(false)}
                />
              ))}

              <Styles.AllResultsLink
                data-testid="header-search-input-all-results-link"
                to={allResultsPageUrl}
                onClick={hideSearchDropDown}
              >
                {t('core.header.search.see_all', {
                  results: data?.pageInfo.total,
                })}
              </Styles.AllResultsLink>
            </>
          )}
          {!isPending && searchResults.length === 0 && !isDelayActive && (
            <Text className="p-2">{t('core.header.search.no_results')}</Text>
          )}
          {isError && (
            <Text className="p-2">{t('core.header.search.error')}</Text>
          )}
        </Styles.SearchResultsPanel>
      )}
      {(minCharactersNotice || showSearchResults) && (
        <Portal>
          <UnderlayMask />
        </Portal>
      )}
    </Styles.SearchWrapper>
  );
};

export default SearchInput;
