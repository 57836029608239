import { Box, Container, Flex, chakra } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Trans, useTranslation } from 'react-i18next';
import { Heading } from '@swibeco/ui';
import {
  isPremiumUser,
  PlusDealSlugs,
  PRODUCT_PAGE_ROOT,
} from '@swibeco/shared';
import { useUserSubscription } from '@swibeco/ecommerce';
import { selectors as coreSelectors } from '@swibeco/core';
import { useSelector } from 'react-redux';
import { useCanAccessPlusLanding } from '@swibeco/shared-web';
import { useHistory } from 'react-router-dom';
import { PlusLandingBanner } from '../../components/PlusLanding/PlusLandingBanner';
import { ScrollTopButton } from '../../components/ScrollTopButton';
import { TopCategoryMenu } from '../../components/TopCategoryMenu';
import { ReactComponent as PlusShapeLeft } from '../../assets/svgs/plus_shape_left.svg';
import { ReactComponent as PlusShapeRight } from '../../assets/svgs/plus_shape_right.svg';
import { PlusUsefulInformation } from '../../components/PlusLanding/PlusUsefulInformation';
import { PlusHowToActivate } from '../../components/PlusLanding/PlusHowToActivate';
import { PlusPlans } from '../../components/PlusLanding/PlusPlans';
import PlusLandingTabs from '../../components/PlusLanding/PlusLandingTabs/PlusLandingTabs';

const CPlusShapeLeft = chakra(PlusShapeLeft);
const CPlusShapeRight = chakra(PlusShapeRight);

function PlusLandingPage() {
  const { t } = useTranslation();
  const planRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const locale = useSelector(coreSelectors.getLocale);
  const { plusLandingAccess } = useCanAccessPlusLanding();

  const { subscription } = useUserSubscription();
  const isPremium = subscription && isPremiumUser(subscription.type);

  // redirect to plus product page(using slugs) if flag is disabled
  useEffect(() => {
    if (!plusLandingAccess) {
      const plusTrialLink = `${PRODUCT_PAGE_ROOT}/${PlusDealSlugs[locale]}`;
      history.push(plusTrialLink);
    }
  }, [plusLandingAccess, locale, history]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{t('core.ecommerce.plus.page.title')}</title>
      </Helmet>
      <TopCategoryMenu />
      <Box bgColor="default.backgroundColor">
        <PlusLandingBanner scrollRef={planRef} />
        <Container
          marginX="auto"
          maxW="1440px"
          w={{
            base: '100%',
            md: '90%',
          }}
        >
          <Flex
            minH="139px"
            position="relative"
            mt="-107px"
            bgColor="default.backgroundColor"
            borderRadius="40px 40px 0px 0px"
            justifyContent="center"
            alignItems="center"
            flexDir="column"
            pt="36px"
            textAlign="center"
            gap="8px"
            p={{
              base: '36px',
              md: '0px',
            }}
            zIndex={1}
          >
            <Heading level="h2">
              <Trans
                i18nKey="core.ecommerce.plus.main.title" // optional -> fallbacks to defaults if not provided
                components={{
                  blue: <Box as="span" color="primary.main" />,
                }}
              />
            </Heading>
            {/* <Text>{t('core.ecommerce.plus.main.description')}</Text> */}
          </Flex>
        </Container>
        <Container pb="160px" marginX="auto" maxW="1440px" w="90%">
          <Flex gap={{ base: '80px', lg: '120px' }} flexDir="column">
            <Flex
              justifyContent="center"
              alignItems="center"
              pos="relative"
              zIndex={3}
            >
              <PlusLandingTabs />
            </Flex>
            {!isPremium && <PlusHowToActivate />}
            {!isPremium && <PlusPlans ref={planRef} />}
            <PlusUsefulInformation />
          </Flex>
        </Container>
        <ScrollTopButton />
        <CPlusShapeLeft
          position="absolute"
          left={0}
          top="15%"
          maxWidth="100vw"
          zIndex={2}
        />
        <CPlusShapeRight
          position="absolute"
          right={0}
          top="74%"
          maxWidth="100vw"
          zIndex={2}
        />
      </Box>
    </HelmetProvider>
  );
}

export default PlusLandingPage;
