import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { selectors } from '@swibeco/security';

type AuthenticatedRouteProps = RouteProps;

const AuthenticatedRoute = ({
  component,
  ...rest
}: AuthenticatedRouteProps) => {
  const isAuthenticated = useSelector(selectors.isAuthenticated);
  const Component = component;
  const currentLocation = useLocation();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated === null) {
          // if we do not yet know if we're authenticated, just return null
          return null;
        }

        /**
         * return the child component only if the user is authenticated,
         * otherwhise redirect to the login page
         */
        return isAuthenticated && Component ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/security/login',
              state: { referrer: currentLocation },
            }}
          />
        );
      }}
    />
  );
};

export default AuthenticatedRoute;
