export enum AnalyticsEvents {
  PURCHASE = "purchase",
  LOGIN = "login",
  SELECT_PAYMENT_INFO = "select_payment_info",
  BEGIN_CHECKOUT = "begin_checkout",
  ADD_TO_WHISLIST = "add_to_wishlist",
  REMOVE_FROM_WHISLIST = "remove_from_whislist",
  ADD_TO_CART = "add_to_cart",
  VIEW_ITEM = "view_item",
  VIEW_ITEM_LIST = "view_item_list",
  VIEW_CART = "view_cart",
  REMOVE_FROM_CART = "remove_from_cart",
  PAGE_VIEW = "datalayer_ready",
  SELECT_ITEM = "select_item",
  LANGUAGE_CHANGED = "language_changed",
  VIEW_PROMOTION = "view_promotion",
  SELECT_PROMOTION = "select_promotion",
  VISIT_HYPT = "visit_hypt",
  VIEW_HYPT_BANNER = "view_hypt_banner",
}

export type AnalyticsContext = {
  listName: string;
  listId: string;
  category: string;
  quantity: number;
};
