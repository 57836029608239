import { useToast, Button } from '@chakra-ui/react';
import { ColorVariants, ProductVariant } from '@swibeco/types';
import { Toast } from '@swibeco/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EmptyHeart } from '../../assets/images/empty-heart.svg';

const WishlistButton = ({
  deal,
  removeFromFavourites,
  addToFavourites,
}: {
  deal: ProductVariant | undefined;
  removeFromFavourites: (id: number) => Promise<void>;
  addToFavourites: (id: number) => Promise<void>;
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const handleAddToFavourites = async (id: number) => {
    try {
      await addToFavourites(id);
      toast({
        id: 'toggle-item-add-success',
        position: 'top-right',
        isClosable: true,
        duration: 3500,

        render: ({ onClose }) => (
          <Toast
            variant={ColorVariants.Success}
            onCloseButtonClick={onClose}
            legacy={false}
          >
            {t(
              'core.ecommerce.productpage.product_basket.add_item_to_favourites'
            )}
          </Toast>
        ),
      });
    } catch (error) {
      toast({
        id: 'toggle-item-add-error',
        position: 'top-right',
        isClosable: true,
        duration: 3500,
        render: ({ onClose }) => (
          <Toast
            variant={ColorVariants.Danger}
            onCloseButtonClick={onClose}
            legacy={false}
          >
            {t('core.error.general')}
          </Toast>
        ),
      });
    }
  };
  const handleRemoveFromFavourites = async (id: number) => {
    try {
      await removeFromFavourites(id);
      toast({
        id: 'toggle-item-remove-success',
        position: 'top-right',
        isClosable: true,
        duration: 3500,
        render: ({ onClose }) => (
          <Toast
            variant={ColorVariants.Success}
            onCloseButtonClick={onClose}
            legacy={false}
          >
            {t(
              'core.ecommerce.productpage.product_basket.remove_item_from_favourites'
            )}
          </Toast>
        ),
      });
    } catch (error) {
      toast({
        id: 'toggle-item-remove-error',
        position: 'top-right',
        isClosable: true,
        duration: 3500,
        render: ({ onClose }) => (
          <Toast
            variant={ColorVariants.Danger}
            onCloseButtonClick={onClose}
            legacy={false}
          >
            {t('core.error.general')}
          </Toast>
        ),
      });
    }
  };

  return (
    <Button
      padding="0"
      margin="0"
      border="0"
      w="50px"
      h="50px"
      color="default.main"
      borderRadius="50%"
      onClick={() => {
        if (deal?.id) {
          if (deal.inWishlist) {
            handleRemoveFromFavourites(deal.id);
          } else {
            handleAddToFavourites(deal.id);
          }
        }
      }}
      sx={
        deal?.inWishlist
          ? { color: 'primary.dark' }
          : {
              '&:hover': {
                color: 'default.strong',
              },
            }
      }
    >
      <EmptyHeart />
    </Button>
  );
};
export default WishlistButton;
