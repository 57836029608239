import { CheckoutContext } from '@swibeco/core';
import { DraftModeProvider, UniverseProvider } from '@swibeco/ecommerce';
import {
  BASKET_PAGE,
  CHECKOUT,
  CHECKOUT_CONFIRMATION,
  DEFAULT_SORT,
  ECOMMERCE_HOME,
  ORDERS,
  PLUS_LANDING_PAGE,
  PRODUCT_PAGE,
  PROFILE,
  SEARCH_RESULTS,
  SWIPOINTS,
  UNIVERSE,
  FAVOURITES_PAGE,
} from '@swibeco/shared';
import { AuthenticatedRoute } from '@swibeco/shared-web';
import { Loader } from '@swibeco/ui';
import React, { Suspense, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Switch, matchPath, useLocation } from 'react-router-dom';
import Announcement from '../components/Announcement/Announcement';
import useRouterParams from '../hooks/useRouterParams';
import PlusLandingPage from '../pages/PlusLandingPage/PlusLandingPage';
import UniverseRouter from './UniverseRouter';

const ConfirmationPage = React.lazy(
  /* istanbul ignore next */ () => import('../pages/Checkout/ConfirmationPage')
);

const CheckoutFunnel = React.lazy(
  /* istanbul ignore next */ () => import('../pages/Checkout/CheckoutFunnel')
);

const HomePage = React.lazy(
  /* istanbul ignore next */ () => import('../pages/HomePage')
);

const ProductPage = React.lazy(
  /* istanbul ignore next */ () => import('../pages/ProductPage')
);

const FavouritesPage = React.lazy(
  /* istanbul ignore next */ () => import('../pages/Favourites')
);

const OrdersPage = React.lazy(
  /* istanbul ignore next */ () => import('../pages/OrdersPage')
);
const BasketPage = React.lazy(
  /* istanbul ignore next */ () => import('../pages/Checkout/BasketPage')
);
const SearchResultsPage = React.lazy(
  /* istanbul ignore next */ () => import('../pages/SearchResultsPage')
);
const ProfilePage = React.lazy(
  /* istanbul ignore next */ () => import('../pages/User/ProfilePage')
);
const SwipointsPage = React.lazy(
  /* istanbul ignore next */ () => import('../pages/Wallet/SwipointsPage')
);

const Router = () => {
  const { params } = useRouterParams();
  const location = useLocation();
  const isUniversePage = useMemo(
    () => matchPath(location.pathname, UNIVERSE),
    [location.pathname]
  );

  const getDefaultValues = () => {
    const urlSearchParams = new URLSearchParams(location.search);

    const buyableWithSwipoints = urlSearchParams.get('buyableWithSwipoints');
    const urlBuyableWithSwipoints =
      buyableWithSwipoints === 'true' || buyableWithSwipoints === 'false'
        ? buyableWithSwipoints === 'true'
        : undefined;
    /*  useRouterParams doesn't have access to DraftModeProvider
            set params from URLSearchParams to cover default values cases */
    return {
      sort: {
        value: params.sort || urlSearchParams.get('sort') || DEFAULT_SORT,
      },
      brands:
        params.brands ||
        urlSearchParams
          .get('brands')
          ?.split(',')
          .map((b) => ({ label: b, value: b })) ||
        [],
      buyableWithSwipoints:
        params.buyableWithSwipoints || urlBuyableWithSwipoints || false,
      reimbursable:
        params.reimbursable || urlSearchParams.get('reimbursable') || false,
      hasNewOffers:
        params.hasNewOffers || urlSearchParams.get('hasNewOffers') || false,
      offerType: params.offerType
        ? params.offerType.map((offerType) => offerType.value)
        : urlSearchParams.get('offerType')?.split(','),
      offersFrom: params.offersFrom
        ? params.offersFrom.map((from) => from.value)
        : urlSearchParams.get('offersFrom')?.split(','),
    };
  };

  const filterFormContext = useForm({
    mode: 'onChange',
    defaultValues: getDefaultValues(),
    shouldUnregister: false,
  });

  useEffect(() => {
    if (isUniversePage) {
      filterFormContext.reset(getDefaultValues());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUniversePage]);

  return (
    <Switch>
      <Suspense fallback={<Loader />}>
        <Announcement />
        <AuthenticatedRoute path={ECOMMERCE_HOME} exact component={HomePage} />
        <AuthenticatedRoute path={PROFILE} exact component={ProfilePage} />
        <AuthenticatedRoute path={SWIPOINTS} exact component={SwipointsPage} />
        <AuthenticatedRoute path={ORDERS} exact component={OrdersPage} />
        <AuthenticatedRoute
          path={FAVOURITES_PAGE}
          exact
          component={FavouritesPage}
        />
        <AuthenticatedRoute
          path={CHECKOUT_CONFIRMATION}
          exact
          component={ConfirmationPage}
        />
        <CheckoutContext>
          <AuthenticatedRoute path={BASKET_PAGE} exact component={BasketPage} />
          <AuthenticatedRoute
            path={CHECKOUT}
            exact
            component={CheckoutFunnel}
          />
        </CheckoutContext>

        <AuthenticatedRoute path={PRODUCT_PAGE} exact component={ProductPage} />
        <AuthenticatedRoute
          path={PLUS_LANDING_PAGE}
          exact
          component={PlusLandingPage}
        />
        <UniverseProvider>
          <FormProvider {...filterFormContext}>
            <DraftModeProvider>
              <AuthenticatedRoute path={UNIVERSE} component={UniverseRouter} />
            </DraftModeProvider>
          </FormProvider>
        </UniverseProvider>
        <UniverseProvider>
          <FormProvider {...filterFormContext}>
            <DraftModeProvider>
              <AuthenticatedRoute
                path={SEARCH_RESULTS}
                component={SearchResultsPage}
              />
            </DraftModeProvider>
          </FormProvider>
        </UniverseProvider>
      </Suspense>
    </Switch>
  );
};

export default Router;
