import { Card, CardBody, Flex, useToken } from '@chakra-ui/react';
import { selectors as coreSelectors } from '@swibeco/core';
import { ecommerceApi } from '@swibeco/ecommerce';
import { selectors } from '@swibeco/security';
import {
  PROFILE,
  SWIPOINTS,
  PlusDealSlugs,
  isProUser,
  PLUS_LANDING_PAGE,
} from '@swibeco/shared';
import { UserType } from '@swibeco/types';
import { Link, Text } from '@swibeco/ui';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useCanAccessPlusLanding } from '../../../../../hooks';
import { ReactComponent as IconFavorite } from '../../../../../assets/images/favorite.svg';
import { ReactComponent as IconSwipoints } from '../../../../../assets/images/panel-swipoints.svg';
import { ReactComponent as IconPlus } from '../../../../../assets/images/plus.svg';
import { ReactComponent as IconProfile } from '../../../../../assets/images/profile.svg';
import ProfileSwipointsBalance from '../../../ProfileSwipointsBalance';

type PersonalCornerProps = {
  userData: UserType | undefined;
  closeBottomSheet: () => void;
};

const PersonalCorner = ({
  userData,
  closeBottomSheet,
}: PersonalCornerProps) => {
  const { t } = useTranslation();
  const locale = useSelector(coreSelectors.getLocale);
  const isAuthenticated = useSelector(selectors.isAuthenticated);
  const shadowColor = useToken('colors', 'shadow.primary');
  const { plusLandingAccess } = useCanAccessPlusLanding();
  const { data: wishlistCount } = useQuery<number>({
    enabled: !!isAuthenticated,
    queryKey: ['wishlistCount'],
    queryFn: ecommerceApi.getWishlistCount,
  });
  const showPlusLandingPage =
    userData && !isProUser(userData.type) && plusLandingAccess;
  return (
    <Flex direction="column" gap="8px">
      <Text variant="default-important" bold>
        {t('core.header.userprofile.menu.personal.corner')}
      </Text>

      {/* Personal Information Card */}
      <Link
        to={PROFILE}
        onClick={closeBottomSheet}
        style={{ textDecoration: 'none', display: 'block' }}
      >
        <Card
          boxShadow={`0px 3px 6px 0px ${shadowColor}`}
          _hover={{
            backgroundColor: 'default.main',
            cursor: 'pointer',
          }}
        >
          <CardBody p="16px">
            <Flex
              gap="8px"
              align="center"
              color="unset"
              fontWeight="normal"
              _hover={{
                color: 'default.black',
              }}
              data-testid="header-userprofile-menu"
            >
              <IconProfile />
              <Text m="0">
                {t('core.header.userprofile.menu.personal_informations')}
              </Text>
            </Flex>
          </CardBody>
        </Card>
      </Link>

      {/* Plus Landing Page Card */}
      {showPlusLandingPage && (
        <Link
          to={
            plusLandingAccess
              ? PLUS_LANDING_PAGE
              : `/core/product/${PlusDealSlugs[locale]}`
          }
          onClick={closeBottomSheet}
          style={{ textDecoration: 'none', display: 'block' }}
        >
          <Card
            boxShadow={`0px 3px 6px 0px ${shadowColor}`}
            _hover={{
              backgroundColor: 'default.main',
              cursor: 'pointer',
            }}
          >
            <CardBody p="16px">
              <Flex
                gap="8px"
                align="center"
                color="unset"
                fontWeight="normal"
                _hover={{
                  color: 'default.black',
                }}
              >
                <IconPlus />
                <Text m="0">{t('core.header.userprofile.panelmenu.plus')}</Text>
              </Flex>
            </CardBody>
          </Card>
        </Link>
      )}

      {/* Swipoints Card */}
      <Link
        to={SWIPOINTS}
        onClick={closeBottomSheet}
        style={{ textDecoration: 'none', display: 'block' }}
      >
        <Card
          boxShadow={`0px 3px 6px 0px ${shadowColor}`}
          _hover={{
            backgroundColor: 'default.main',
            cursor: 'pointer',
          }}
        >
          <CardBody p="16px">
            <Flex
              gap="8px"
              align="center"
              color="unset"
              fontWeight="normal"
              _hover={{
                color: 'default.black',
              }}
            >
              <IconSwipoints />
              <Text mb="0" mr="16px">
                {t('core.header.userprofile.menu.swipoints')}
              </Text>
              <ProfileSwipointsBalance userData={userData} />
            </Flex>
          </CardBody>
        </Card>
      </Link>

      {/* Wishlist Card */}
      <a
        href="/#/user/wishlist"
        onClick={closeBottomSheet}
        style={{ textDecoration: 'none', display: 'block' }}
      >
        <Card
          boxShadow={`0px 3px 6px 0px ${shadowColor}`}
          _hover={{
            backgroundColor: 'default.main',
            cursor: 'pointer',
          }}
        >
          <CardBody p="16px">
            <Flex
              gap="8px"
              align="center"
              color="unset"
              fontWeight="normal"
              _hover={{
                color: 'default.black',
              }}
            >
              <IconFavorite />
              <Text m="0">
                {t('core.header.userprofile.menu.favorites')} ({wishlistCount})
              </Text>
            </Flex>
          </CardBody>
        </Card>
      </a>
    </Flex>
  );
};
export default PersonalCorner;
